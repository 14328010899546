import {
	ApolloClient,
	ApolloLink,
	createHttpLink,
	DocumentNode,
	from,
	HttpLink,
	InMemoryCache,
	split
} from '@apollo/client';
import { gql } from '@apollo/client';
import { getCookie, getCookies, removeCookies } from 'cookies-next';
import { camelCase, mapKeys, snakeCase } from 'lodash';
import { ACCESS_TOKEN_COOKIE } from '../../api-common/access-token';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { SESSION_COOKIE } from 'common/session/handle-session';
import { WebSocketLink } from '@apollo/client/link/ws';
import ws from 'ws';
import { getMainDefinition } from '@apollo/client/utilities';

const httpLink = createHttpLink({
	uri: process.env.NEXT_PUBLIC_GRAPHQL_URL
});

const authLink = setContext((_, { headers }) => {
	const accessToken = getCookie(ACCESS_TOKEN_COOKIE);

	return {
		ssrMode: true,
		headers: {
			...headers,
			...(accessToken ? { authorization: `Bearer ${accessToken}` } : {})
		}
	};
});

const errorLink = onError(
	({ graphQLErrors, networkError, operation, forward }) => {
		if (graphQLErrors)
			graphQLErrors.forEach(({ message, locations, path }) => {
				console.error(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
				);

				if (handleJwtError(message)) {
					return forward(operation);
				}
			});
		if (networkError) console.error(`[Network error]: ${networkError}`);
	}
);

let wsLink;
if (process.browser) {
	wsLink = new WebSocketLink({
		uri: process.env.NEXT_PUBLIC_GRAPHQL_WS,
		// webSocketImpl: ws,
		options: {
			reconnect: true,
			connectionParams: async () => {
				const accessToken = getCookie(ACCESS_TOKEN_COOKIE);

				return {
					headers: {
						...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
					}
				};
			}
		}
	});
}

const handleJwtError = (message: string) => {
	if (message === 'Could not verify JWT: JWTExpired') {
		removeCookies(ACCESS_TOKEN_COOKIE);
		removeCookies(SESSION_COOKIE);

		window.location.reload();

		return true;
	}

	return false;
};

const transportLinks = process.browser
	? split(
			// split based on operation type
			({ query }) => {
				const definition = getMainDefinition(query);
				return (
					definition.kind === 'OperationDefinition' &&
					definition.operation === 'subscription'
				);
			},
			wsLink as any,
			authLink.concat(httpLink)
	  )
	: authLink.concat(httpLink);

export const client = new ApolloClient({
	cache: new InMemoryCache(),
	ssrMode: true,
	// link: from([authLink, errorLink, httpLink])
	link: from([transportLinks])
	// link: new ApolloLink((operation, forward) => {
	//   return forward({
	//     ...operation,
	//     // Make sure this conversion is cached, so you don't have to
	//     // redo it every time you fetch this query:
	//   //   variables: snakeCaseVariables(operation.variables)
	//   }).map((result) => {
	//     return {
	//       ...result,
	//       data: camelCaseResultData(result.data),
	//     };
	//   });
	// }),
});

export { gql };
