import * as jwt from 'jsonwebtoken';

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

export type Role = 'customer' | 'administrator' | 'specialist' | 'place';

const secret = process.env.JWT_SECRET;

export const createAccessToken = (user: User, role: Role, specialistId?: string, placeId?: string) => {
    const tokenContents = {
        user,
        sub: user.id.toString(),
        iat: Date.now() / 1000,
        iss: 'http://localhost:3000',
        'https://hasura.io/jwt/claims': {
            'x-hasura-allowed-roles': ['anonymous', role],
            'x-hasura-default-role': role,
            'x-hasura-role': role,
            'x-hasura-user-id': user.id.toString(),
            ...(specialistId ? { 'x-hasura-specialist-id': specialistId.toString() } : {}),
            ...(placeId ? { 'x-hasura-place-id': placeId.toString() } : {})

        },
        exp: Math.floor(Date.now() / 1000) + (24 * 60 * 60 * 3600)
    }

    const accessToken = jwt.sign(tokenContents, secret || 'what');

    return accessToken;
};

export const ACCESS_TOKEN_COOKIE = 'accessToken';