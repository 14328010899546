import '../styles/globals.scss';
import 'mapbox-gl/dist/mapbox-gl.css'
import '@fullcalendar/common/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'

import Head from "next/head";

import type { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import { client } from "../common/apollo/apollo-client";
import { useEffect } from 'react';
import PiwikPro from '@piwikpro/react-piwik-pro';

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    PiwikPro.initialize(process.env.NEXT_PUBLIC_PIWIK_CONTAINER_ID as any, 'https://znany-pl.piwik.pro');
  }, []);

  return (
    
    <ApolloProvider client={client}>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="author" content="Bartosz Kostrowiecki, Tomasz Łuniewski" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <Component {...pageProps} />
    </ApolloProvider>
  );
}

export default MyApp;
