import { getCookie, removeCookies } from 'cookies-next';
import { EventUserDto } from 'entities/event.dto';
import { PlaceSpecialistDto } from 'entities/place.dto';
import { NextPageContext } from 'next';
import { ACCESS_TOKEN_COOKIE, Role } from '../../api-common/access-token';

export const SESSION_COOKIE = 'session';

const GET_USER_SESSION_QUERY = `
  query get_user_session($id: Int!) {
    users_by_pk(id: $id) {
			is_payment_enabled
			payment_expiration_at
      email
      first_name
      id
      last_name
      phone
      role
      notification_settings
			invoice_name
			invoice_nip
			invoice_postal_code
			invoice_city
			invoice_street
			invoice_country
      specialist{
				shown_name
				user {
					is_payment_enabled
					payment_expiration_at
				}
        id
        avatar_url
        profession_id
				places_specialists {
					id
					place {
						id
						name
					}
				}
        profession {
          id
          name
        }
       addressesses {
        id
        building_no
        postal_code
        street
        calendar_settings
        city
        geolocation
        is_online
        latitude
        longitude
				
			}
			calendar_settings_by_specialist_id {
				addressess {
					id
					place_id
					building_no
					postal_code
					street
					calendar_settings
					city
					geolocation
					is_online
					latitude
					longitude
				}
				address_id
				calendar_settings
				specialist_id
				id
			}
       services {
        id
        name
        price
        timespan
        addresses
       }
      }
      place {
        id
        name
        description
        avatar_url
        professions_places {
          id
          profession_id
					profession {
						id
						name
					}
        }
				profession {
					id
					name
				}
      }
    }
    favorite_specialists {
      specialist_id
      user_id
      id
    }
		events_users(where: {user_id: {_eq: $id}}) {
			event_id
			id
		}
  }
`;

const execute = async (variables: any, accessToken: string) => {
	const fetchResponse = await fetch(
		process.env.NEXT_PUBLIC_GRAPHQL_URL as any,
		{
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				authorization: `Bearer ${accessToken}`
			} as any,
			body: JSON.stringify({
				query: GET_USER_SESSION_QUERY,
				variables
			})
		}
	);
	const data = await fetchResponse.json();
	console.log('DEBUG: ', data);
	return data;
};

export const handleServerSession = async (context: NextPageContext) => {
	const accessToken = getCookie(ACCESS_TOKEN_COOKIE, context);
	const session = getCookie(SESSION_COOKIE, context);

	if (session && accessToken) {
		try {
			const parsedSession = JSON.parse(session.toString());

			const { data, errors } = await execute(
				{ id: (parsedSession! as any).id as any },
				accessToken?.toString()
			);

			if (errors || !data.users_by_pk) {
				removeCookies(ACCESS_TOKEN_COOKIE, context);
				removeCookies(SESSION_COOKIE, context);

				return {
					accessToken: null,
					session: null
				};
			}

			data.users_by_pk.specialist_id = data.users_by_pk.specialist
				? data.users_by_pk.specialist.id
				: null;

			return {
				accessToken: accessToken || null,
				session: data
					? {
							...data.users_by_pk,
							favorite_specialists: data.favorite_specialists,
							events_users: data.events_users
					  }
					: null
			};
		} catch {
			return {
				accessToken: null,
				session: null
			};
		}
	}

	removeCookies(ACCESS_TOKEN_COOKIE, context);
	removeCookies(SESSION_COOKIE, context);

	return {
		accessToken: null,
		session: null
	};
};

export type NotificationSettingsJSON = {
	emailNotifications: boolean;
	smsNotifications: boolean;
};

export type UserSessionDto = {
	accessToken?: string | null;
	session?: {
		id: number;
		is_payment_enabled: boolean;
		payment_expiration_at: string;
		first_name: string;
		last_name: string;
		phone: string;
		email: string;
		role: Role;
		specialist_id?: number;
		notification_settings: NotificationSettingsJSON;
		invoice_name: string | null;
		invoice_nip: string | null;
		invoice_postal_code: string | null;
		invoice_city: string | null;
		invoice_street: string | null;
		invoice_country: string | null;
		specialist: {
			id?: number;
			shown_name: string;
			avatar_url: string;
			profession_id?: number;
			profession?: {
				id?: number;
				name?: string;
			};
			places_specialists: PlaceSpecialistDto[];
		};
		place?: {
			id?: number;
			avatar_url: string;
			professions: {
				id?: number;
				name: string;
			};
		};
		favorite_specialists: {
			id: number;
			specialist_id: number;
			user_id: number;
		}[];
		events_users: EventUserDto[];
	} | null;
};
